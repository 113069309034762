@import '@aurora/shared-client/styles/mixins/_focus.pcss';

.lia-widget-btn {
  @include default-focus-box-shadow();

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &-icon {
    align-self: flex-start;
    margin-top: 2px;

    > svg {
      height: 45px;
      width: 45px;
    }
  }
}
